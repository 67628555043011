import React from 'react'
import './Landing_page.css'
import { Link } from 'react-router-dom'

export default function Landing_page() {
  return (
    <>
    <section class="breadcrumb-area overlay-dark d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                      
                        <div class="breadcrumb-content text-center">
                            <h2 class="m-0">Staking </h2>
                            <ol class="breadcrumb d-flex justify-content-center">
                                <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                             
                                <li class="breadcrumb-item active">Staking </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    </>
  )
}
