// import React, { useState } from "react";
// import { Button, Drawer } from "antd";
// import { Link } from "react-router-dom";

// export default function Offcanvas_nav({ onClose, showDrawer, open }) {
//   return (
//     <div>
//       <Drawer
//         title="Menu"
//         placement="right"
//         onClose={onClose}
//         open={open}
//         className="drawe_bg"
//       >
//         <div
//           // id="menu"

//           aria-hidden="true"
//         >
//           <div class="modal-dialog dialog-animated">
//             <div class="modal-content h-100">
//               <div class="menu modal-body">
//                 <div class="row w-100">
//                   <div class="items p-0 col-12 text-center">
//                     <ul class="navbar-nav items mx-auto text-white">
//                       <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/">
//                             Home
//                           </Link>{" "}
//                         </a>
//                       </li>
//                        <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/Staking">
//                             Staking
//                           </Link>{" "}
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link" href='https://digifortune.gitbook.io/digifortune.net' target="_blank">
//                           {" "}
//                           <span style={{ fontSize: '16px' }}>Features</span>
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link" href='https://token.digifortune.net/assets/content/white-paper.pdf' target="_blank">
//                             <span style={{ fontSize: '16px' }}>Whitepaper</span>
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/Refferal_Reward">
//                           Refferal Reward
//                           </Link>
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/Stake_History">
//                           Stake History
//                           </Link>
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/Swap">
//                             Swap
//                           </Link>
//                         </a>
//                       </li>
//                       <li class="nav-item">
//                         <a class="nav-link">
//                           <Link className="nav_link" to="/Blogs">
//                             Blogs
//                           </Link>
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Drawer>
//     </div>
//   );
// }

import React from "react";
import { Drawer } from "antd";
import { Link, useNavigate } from "react-router-dom";

export default function Offcanvas_nav({ onClose, showDrawer, open }) {
  const navigate = useNavigate();

  const handleMenuClick = (path) => {
    onClose(); // Close the drawer
    if (path.startsWith("http")) {
      window.open(path, "_blank"); // Open external links in a new tab
    } else {
      navigate(path); // Navigate to the specified path
    }
  };

  return (
    <div>
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={open}
        className="drawe_bg"
      >
        <div className="modal-dialog dialog-animated">
          <div className="modal-content h-100">
            <div className="menu modal-body">
              <div className="row w-100">
                <div className="items p-0 col-12 text-center">
                  <ul className="navbar-nav items mx-auto text-white">
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("/")}>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("/Staking")}>
                        Staking
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("https://digifortune.gitbook.io/digifortune.net")} target="_blank">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("https://token.digifortune.net/assets/content/white-paper.pdf")} target="_blank">
                        Whitepaper
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("/Refferal_Reward")}>
                        Refferal Reward
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("/Stake_History")}>
                        Stake History
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("https://digiswap.net")} target="_blank">
                        Swap
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" style={{ fontSize: '14px' }} onClick={() => handleMenuClick("https://blogs.digifortune.net")} target="_blank">
                        Blogs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}




