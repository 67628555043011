import React from 'react'
import { Link } from 'react-router-dom'

export default function Features() {
  return (
    <div>
        <section class="breadcrumb-area overlay-dark d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                      
                        <div class="breadcrumb-content text-center">
                            <h2 class="m-0">Tier System</h2>
                            <ol class="breadcrumb d-flex justify-content-center">
                                <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item active">Tier System</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="features-section-custom">
            <div class="title-section">
                <h2>Feature of Digifortune</h2>
                <p>We have a professional system design and development teams.
                    develop the project to make it as perfect as possible.</p>
                <div class="line-break"></div>
                <a target='_blank' href="https://t.me/digifurtune01">
                    <div class="telegram-link-btn">
                        JOIN US ON TELEGRAM
                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                            height="16" fill="currentColor" class="bi bi-send"
                            viewBox="0 0 16 16">
                            <path
                                d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                    </div>
                </a>
            </div>
            <div class="boss-div-fea">
                <div class="center-ctn-fea">
                    <div class="features-content-era">
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/fast-transaction-food.fw_.png' />
                            <h2>Fast transaction</h2>
                            <p>We have create a stable and fast token for
checking transactions at any time</p>
                        </div>
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/reduction.fw_.png' />
                            <h2>Low trading fees</h2>
                            <p>Low transaction fees compared with
other payment methods</p>
                        </div>
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/shield.fw_.png' />
                            <h2>100% Security</h2>
                            <p>Team has designed a system that is the most secure. to increase user confidence</p>
                        </div>
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/data-transfer-food.fw_.png' />
                            <h2>Modern Platform</h2>
                            <p>We have a modern platform that is easy to
use and supports new payment systems</p>
                        </div>
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/exchange-currencyfw.fw_.png' />
                            <h2>Blockchain support</h2>
                            <p>Digifortune  token can be used to buy products and services on our platform for all items</p>
                        </div>
                        <div class="f-data-block">
                            <img src='https://deencoin.io/wp-content/uploads/2023/08/stockfood.fw_.png' />
                            <h2>Exchange Support</h2>
                            <p>We have an exchange support for Dex exchange and Central exchange systems</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
