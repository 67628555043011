import React, { useEffect, useState } from "react";
import {
  Mango_Man_Abi,
  Mango_Man_Contract,
  Mango_Man_Token_Abi,
  Mango_Man_Token_Contract,
} from "../../Contract/Contract";
import axios from "axios";
import { useCollapse } from "react-collapsed";
import { data } from "./Data";
import Collapse from "./Collapse";
import { toast } from "react-hot-toast";
import { InfinitySpin } from "react-loader-spinner";
import Offcanvas_nav from "../Offcanvas/Offcanvas";
import Landing_page from "../Landing_page/Landing_page";
import Apply from "../Apply/Apply";
import Loading_spinner from "../Loading/Loading_spinner";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAccount } from "wagmi";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import Web3 from "web3";
import { readContract } from "@wagmi/core";


export default function Staking() {
  const { address } = useAccount();

  const [title, settitle] = useState("");
  const [Planes, setPlanes] = useState([]);
  const [Spinner, setSpinner] = useState(false);
  const [getValue, setgetValue] = useState(0);
  const [refAddress, setRefAddress] = useState("");
  const [copied, setCopied] = useState(false);
  const [ConditionAl, setConditionAl] = useState("0");
  const [Loading_Spinner, setLoading_Spinner] = useState(false);

  const config = {
    defaultExpanded: title || false,
  };
  //let webSupply = new Web3("https://bsc-testnet.public.blastapi.io");
  let webSupply = new Web3("https://bsc-dataseed1.binance.org");
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  const Stake = async (index, amount, stakeDuration, apr) => {
    try {
      if (getValue == 0) {
        setSpinner(false);
        toast.error("Please Enter Amount");
      } else {
        if (getValue < parseInt(amount)) {
          if (window.location.href.includes("ref")) {
            setSpinner(true);

            let UserID = window.location.href.split("=");
            UserID = UserID[UserID.length - 1];
            let value = webSupply.utils.toWei(getValue.toString());
            const { request } = await prepareWriteContract({
              address: Mango_Man_Token_Contract,
              abi: Mango_Man_Token_Abi,
              functionName: "approve",
              args: [Mango_Man_Contract, value],
              account: address,
            });
            const { hash } = await writeContract(request);
            const data = await waitForTransaction({
              hash,
            });

            toast.success("Approve Successfully 🎉");
            setTimeout(async () => {
            setSpinner(true);

              const { request } = await prepareWriteContract({
                address: Mango_Man_Contract,
                abi: Mango_Man_Abi,
                functionName: "referralStake",
                args: [index, value, UserID],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              let Add_Refferal_Record = await axios.post(
                "https://refferal.womenempowerment.online/Add_Refferal_data",
                {
                  Refferal_Address: UserID,
                  Staker_Address: address,
                  Refferal_Price: getValue.toString(),
                }
              );
              let res = await axios.post(
                "https://refferal.womenempowerment.online/Add_Stake_History",
                {
                  Address: address,
                  Amount: getValue,
                  Date: Math.floor(new Date().getTime() / 1000.0),
                  APR: apr,
                  Duration: stakeDuration,
                }
              );
              console.log("Res_Add_Stake", res);
              toast.success(Add_Refferal_Record.data.msg);
              toast.success("Transaction Successfully 🎉");
              setSpinner(false);
            }, 2000);

            setSpinner(false);
          } else {
            setSpinner(true);
            let value = webSupply.utils.toWei(getValue.toString());
            const { request } = await prepareWriteContract({
              address: Mango_Man_Token_Contract,
              abi: Mango_Man_Token_Abi,
              functionName: "approve",
              args: [Mango_Man_Contract, value],
              account: address,
            });
            const { hash } = await writeContract(request);
            const data = await waitForTransaction({
              hash,
            });
            toast.success("Approve Successfully 🎉");
            setTimeout(async () => {
            setSpinner(true);

              const { request } = await prepareWriteContract({
                address: Mango_Man_Contract,
                abi: Mango_Man_Abi,
                functionName: "stake",
                args: [index, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              toast.success("Transaction Successfully 🎉");
              let res = await axios.post(
                "https://refferal.womenempowerment.online/Add_Stake_History",
                {
                  Address: address,
                  Amount: getValue,
                  Date: Math.floor(new Date().getTime() / 1000.0),
                  APR: apr,
                  Duration: stakeDuration,
                }
              );
              console.log("Res_Add_Stake", res);
              setSpinner(false);
            }, 2000);
            setSpinner(false);
          }
        } else {
          setSpinner(false);
          toast.error("Amount exceeds maximum limit");
        }
      }
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  const Plane = async () => {
    try {
      setLoading_Spinner(true);
      const contractOf = new webSupply.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );
      let arry = [];

      for (let i = 0; i <= 9; i++) {
        let Amount = await contractOf.methods.plans(i).call();
        let overallStaked = webSupply.utils.fromWei(Amount.overallStaked.toString());
        overallStaked = parseFloat(overallStaked).toFixed(2);
        // console.log("Amount?.stakesCount",);
        arry.push({
          overallStaked: overallStaked,
          stakeDuration: Amount?.stakeDuration,
          stakesCount: Amount?.stakesCount,
          apr: Amount?.apr,
          earlyPenalty: Amount?.earlyPenalty,
          Max_Stake: data[i].Max_Stake,
          Collapse_data: data[i].Collapse_data,
        });
      }
      setPlanes(arry);
      setLoading_Spinner(false);
    } catch (error) {
      console.log(error);
      setLoading_Spinner(false);
    }
  };

  useEffect(() => {
    Plane();
  }, []);

  const claim = async (index) => {
    try {
     
      const { request } = await prepareWriteContract({
        address: Mango_Man_Contract,
        abi: Mango_Man_Abi,
        functionName: "claimEarned",
        args: [index],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      toast.success("Claim Successfully  🎉");
    } catch (error) {
      console.log(error);
    }
  };

  const get_refferal = async () => {
   
    if (address) {
      setRefAddress(`${window.location.origin}/?ref=${address}`);
    } else {
      setRefAddress("Connect wallet");
    }
  };

  const UnStake = async (index) => {
    try {
      setSpinner(true);
      let OfferCount = await readContract({
        address:Mango_Man_Contract,
        abi:Mango_Man_Abi,
        functionName: "canWithdrawAmountFix",
        args: [index,address],
      });
      OfferCount = parseInt(OfferCount[0]);
      const { request } = await prepareWriteContract({
        address: Mango_Man_Contract,
        abi: Mango_Man_Abi,
        functionName: "unstake",
        args: [index,OfferCount],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      toast.success("UnStake Successfully 🎉");
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  useEffect(() => {
    get_refferal();
  }, []);

  return (
    <div>
      <Landing_page />
      {Loading_Spinner ? (
        <>
          <Loading_spinner />
        </>
      ) : (
        <>
          <section class="staking-area">
            <div id="gameon-accordion" class="container accordion">
              <div class="row justify-content-center">
                <div class="col-12 col-md-10">
                  {Planes.map((items, index) => {
                    return (
                      <>
                        <div class="single-accordion-item">
                          <Collapse
                            title={items.Collapse_data}
                            data={items.overallStaked}
                            getCollapseProps={getCollapseProps}
                            Max_Stake={items.Max_Stake}
                            stakeDuration={items.stakeDuration}
                            apr={items.apr}
                            earlyPenalty={items.earlyPenalty}
                            setgetValue={setgetValue}
                            Stake={Stake}
                            index={index}
                            UnStake={UnStake}
                            setConditionAl={setConditionAl}
                            ConditionAl={ConditionAl}
                            Spinner={Spinner}
                            claim={claim}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <section class="staking-area">
        <div id="gameon-accordion" class="container accordion">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <div class="single-accordion-item p-2">
                <div class="MuiBox-root jss665 ref-box">
                  <div class="text-center referBox">
                    <h2>Refer and Earn</h2>
                    <p>
                      Invite your friend with the following url, if they stake
                      earn direct commision 1%
                    </p>
                    <div className="reffer_main_div">
                      <input
                        type="text"
                        className="Reffer_input"
                        value={refAddress}
                      />
                      <CopyToClipboard
                        text={refAddress}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 2000);
                        }}
                      >
                        <button className="">
                          {copied ? "Copied!" : "Copy Referral"}{" "}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div class="MuiBox-root jss666 ref-url-item text-center mt-3">
                    Please stake DIFO to get your referral url.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Apply />
    </div>
  );
}
