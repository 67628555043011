import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { persistor, store } from "./Redux/store/Store.jsx";
import { PersistGate } from "redux-persist/integration/react";
import "@rainbow-me/rainbowkit/styles.css";
import { BrowserRouter } from "react-router-dom";
import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

const projectId = "ae64d2d938316ce3350fea4c10f6cc79";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [
    alchemyProvider({ apiKey: "gbnhSoSy4pQ2eiWkI2KQoAxiFgAQWVf9" }),
    publicProvider(),
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'Staking',
  projectId: projectId,
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains} modalSize="compact">
              <App />
            </RainbowKitProvider>
          </WagmiConfig>
        </PersistGate>

      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
