import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading_spinner from "../Loading/Loading_spinner";
import { Link } from "react-router-dom";

export default function Stake_History() {
  const lorem = useSelector((state) => state.counter.Address);
  const [get_Stake_History, setget_Stake_History] = useState([])
  const [Spinner, setSpinner] = useState(false)

  const Get_Stake_history = async () => {
    try {
        setSpinner(true)

        let res= await axios.get(`https://refferal.womenempowerment.online/Stake_Info_Address?Address=${lorem}`)
        console.log("Res_Sa",res);
        setget_Stake_History(res.data.data)
        setSpinner(false)

    } catch (error) {
        setSpinner(false)
      console.log(error);
    }
  };
  useEffect(() => {
    Get_Stake_history()
  }, [])
  
  return (
    <div>
        {
            Spinner? <>
             <Loading_spinner />
            </>
            :
            <>
             <section class="breadcrumb-area overlay-dark d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb-content text-center">
                <h2 class="m-0">Stake History</h2>
                <ol class="breadcrumb d-flex justify-content-center">
                  <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>

                  <li class="breadcrumb-item active">Stake History</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="MuiBox-root jss1 sc-ksZaOG gmakkD container "
        style={{ height: "auto", marginTop: "5rem" }}
      >
        <div class="my-earning-view">
          <div class="">
            <Table responsive border>
              <thead>
                <tr className="t_head text-white ">
                  <th className="large_address">Address</th>
                  <th className="large_address">Stake Amount</th>
                  <th className="large_address">APR</th>
                  <th className="large_address">Stake Duration</th>
                  <th className="large_address">Stake Date</th>

                </tr>
              </thead>
              <tbody>
                {get_Stake_History.map((item, index) => {
                  return (
                    <>
                      <tr className="boxx t_head  text-white">
                        <td className="large_address">{item.Address}</td>
                        <td className="large_address text-white">
                          {item.Amount}
                        </td>
                        <td className="large_address  text-white">
                          {item.APR}
                        </td>
                        <td className="large_address  text-white">
                          {item.Duration} Days
                        </td>
                        <td className="large_address  text-white">
                          { moment(item.Date*1000).format("DD/MM/YYYY h:m:s A")}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
            </>
        }
     
    </div>
  );
}
