import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import user3red from "../../assets/images/tree_red.png";
import Default from "../../assets/images/tree_black.png";
import Active from "../../assets/images/tree_green.png";
import Homeicon from "../../assets/images/Homeicon.png";

import activehorse from "../../assets/images/activehorse.png";
import inactivehorse from "../../assets/images/inactivehorse.png";
import blackhorse from "../../assets/images/whitehorse.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import rootHourse from "../../assets/images/rootHourse.png";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
// import HoverTreeNode from "./HoverTreeNode";
import { useSelector } from "react-redux";
import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";

import { useAccount } from "wagmi";
import Web3 from "web3";
// import { API } from "../../API/Api";

var bol = true;

const MintMainImage = () => {
  const { address } = useAccount();
  const lorem = useSelector((state) => state.counter.Address);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Idnumer, setIdnumer] = useState(778899);
  const [arrValue, setArrValue] = useState([]);
  const [getValue, setgetValue] = useState("");
  const [loader, setloader] = useState(false);
  const [searchvalue, setsearchvalue] = useState(address);

  const [userdata, setuserdata] = useState([
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "0",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
  ]);
  let webSupply = new Web3("https://bsc-testnet.public.blastapi.io");

  const referral_API = async () => {
    try {
    
      const contractOf = new webSupply.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );

      let getReferees = await contractOf.methods.getReferees(searchvalue).call()
      console.log("responce", getReferees);
      setArrValue(getReferees)
      let responce
        setloader(true);
        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            name: item.fname,
          });
        });
        console.log("responce", arr);
        setuserdata(getReferees);
        if (bol) {
          setArrValue([...arrValue, arr[0].id]);
          bol = false;
        }
    } catch (e) {
      // setloader(false);
      console.log("Error While calling Referrer API", e);
    }
  };
  function addValue(value) {
    setArrValue([...arrValue, value]);
    // arrValue.push(value)
    // arrValue.push(value)
  }

  function popoutvalue() {
    if (arrValue.length == 1) {
      // setloader(true)
      arrValue.pop();
      arrValue.unshift();
      bol = true;
      // setloader(false)
    } else {
      // setloader(true)
     let a = arrValue.splice(arrValue.length - 2, 1);
      setIdnumer(a[0]);
      // console.log('what is popout value', a[0])
      // setloader(false)
    }
  }

  useEffect(() => {
    referral_API();
    let idinput = document.querySelector(".idinput");
    //   idinput.value = Idnumer;
  }, [Idnumer]);

  const StyledNode = styled.div`
    padding: 5px;
    display: inline-block;
    position: relative;
    &:hover .HoverNodeItemMain {
      display: block;
    }
  `;

  return (
    <>
      <div className="MintingHistory_Main Mobile_MintingHistory_Main">
        <div className="TreeImgMian">
          <Tree
            lineWidth={"2px"}
            lineColor={"#5350EF"}
            lineBorderRadius={"10px"}
            label={
              <StyledNode style={{cursor:"pointer"}}>
                <Image
                  style={{ width: "60px" }}
                  src={
                    userdata[0].package >= 1
                      ? Active
                      : userdata[0].id == ""
                      ? Default
                      : user3red
                  }
                  alt="Image description"
                  fluid={true}
                  onClick={() => (
                    setIdnumer(userdata[0].id), setsearchvalue(address)
                  )}
                />
                {/* <HoverTreeNode data={userdata[0]} /> */}
                <p className="mb-0" style={{ fontSize: "13px" }}>
                  {searchvalue}
                </p>
                <p style={{ fontSize: "13px" }}>{userdata[0]?.id}</p>
              </StyledNode>
            }
          >
            <TreeNode
              label={
                <StyledNode style={{cursor:"pointer"}}>
                  <Image
                    style={{ width: "60px" }}
                    src={
                      userdata[1].package >= 1
                        ? Active
                        : userdata[1].id == ""
                        ? Default
                        : user3red
                    }
                    alt="Image description"
                    fluid={true}
                    className="TreeNodeImage"
                    onClick={() => (
                      setIdnumer(userdata[1].id), setsearchvalue(arrValue[1])
                    )}
                  />
                  <p></p>
                  {/* <HoverTreeNode data={userdata[1]} /> */}
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                  { arrValue[1]===undefined? "No Referees": arrValue[1]?.substring(0, 4) +
                      "..." +
                      arrValue[1]?.substring(arrValue[1]?.length - 4)}
                  </p>
                  <p style={{ fontSize: "13px" }}>{userdata[1]?.id}</p>
                </StyledNode>
              }
            >
              <TreeNode
                label={
                  <StyledNode style={{cursor:"pointer"}}>
                    <Image
                      style={{ width: "60px" }}
                      src={
                        userdata[3].package >= 1
                          ? Active
                          : userdata[3].id == ""
                          ? Default
                          : user3red
                      }
                      alt="Image description"
                      fluid={true}
                      className="TreeNodeImage"
                      onClick={() => (
                        setIdnumer(userdata[3].id), setsearchvalue(arrValue[0])
                      )}
                    />
                    {/* <HoverTreeNode data={userdata[3]} /> */}
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                    { arrValue[0]===undefined? "No Referees": arrValue[0]?.substring(0, 4) +
                      "..." +
                      arrValue[0]?.substring(arrValue[0]?.length - 4)}
                    </p>
                    <p style={{ fontSize: "13px" }}>{userdata[3]?.id}</p>
                  </StyledNode>
                }
              >
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                        src={
                          userdata[7].package >= 1
                            ? Active
                            : userdata[7].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[7].id), setsearchvalue(arrValue[7])
                        )}
                      />
                      {/* <HoverTreeNode data={userdata[7]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[7]===undefined? "No Referees": arrValue[7]?.substring(0, 4) +
                      "..." +
                      arrValue[7]?.substring(arrValue[7]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[7]?.id}</p>
                    </StyledNode>
                  }
                />
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                        src={
                          userdata[8].package >= 1
                            ? Active
                            : userdata[8].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[8].id), setsearchvalue(arrValue[8])
                        )}
                      />
                      {/* <HoverTreeNode data={userdata[8]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[8]===undefined? "No Referees": arrValue[8]?.substring(0, 4) +
                      "..." +
                      arrValue[8]?.substring(arrValue[8]?.length - 4)}
                      
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[8]?.id}</p>
                    </StyledNode>
                  }
                />
              </TreeNode>
              <TreeNode
                label={
                  <StyledNode style={{cursor:"pointer"}}>
                    <Image
                      style={{ width: "60px" }}
                      src={
                        userdata[4].package >= 1
                          ? Active
                          : userdata[4].id == ""
                          ? Default
                          : user3red
                      }
                      onClick={() => (
                        setIdnumer(userdata[4].id), setsearchvalue(arrValue[3])
                      )}
                      alt="Image description"
                      fluid={true}
                      className="TreeNodeImage"
                    />
                    {/* <HoverTreeNode data={userdata[4]} /> */}
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                    { arrValue[3]===undefined? "No Referees": arrValue[3]?.substring(0, 4) +
                      "..." +
                      arrValue[3]?.substring(arrValue[3]?.length - 4)}
                    </p>
                    <p style={{ fontSize: "13px" }}>{userdata[4]?.id}</p>
                  </StyledNode>
                }
              >
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[9].package >= 1
                            ? Active
                            : userdata[9].id == ""
                            ? Default
                            : user3red
                        }
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                        onClick={() => (
                          setIdnumer(userdata[9].id), setsearchvalue(arrValue[9])
                        )}
                      />
                      {/* <HoverTreeNode data={userdata[9]} /> */}
                    
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[9]===undefined? "No Referees": arrValue[9]?.substring(0, 4) +
                      "..." +
                      arrValue[9]?.substring(arrValue[9]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[9]?.id}</p>
                    </StyledNode>
                  }
                />
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[10].package >= 1
                            ? Active
                            : userdata[10].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[10].id), setsearchvalue(arrValue[10])
                        )}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                      />
                      {/* <HoverTreeNode data={userdata[10]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[10]===undefined? "No Referees": arrValue[10]?.substring(0, 4) +
                      "..." +
                      arrValue[10]?.substring(arrValue[10]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[10]?.id}</p>
                    </StyledNode>
                  }
                />
              </TreeNode>
            </TreeNode>

            <TreeNode
              label={
                <StyledNode style={{cursor:"pointer"}}>
                  <Image
                    style={{ width: "60px" }}
                    src={
                      userdata[2].package >= 1
                        ? Active
                        : userdata[2].id == ""
                        ? Default
                        : user3red
                    }
                    onClick={() => (
                      setIdnumer(userdata[2].id), setsearchvalue(arrValue[2])
                    )}
                    alt="Image description"
                    fluid={true}
                    className="TreeNodeImage"
                  />
                  {/* <HoverTreeNode data={userdata[2]} /> */}
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                  { arrValue[2]===undefined? "No Referees": arrValue[2]?.substring(0, 4) +
                      "..." +
                      arrValue[2]?.substring(arrValue[2]?.length - 4)}
                  </p>
                  <p style={{ fontSize: "13px" }}>{userdata[2]?.id}</p>
                </StyledNode>
              }
            >
              <TreeNode
                label={
                  <StyledNode style={{cursor:"pointer"}}>
                    <Image
                      style={{ width: "60px" }}
                      src={
                        userdata[5].package >= 1
                          ? Active
                          : userdata[5].id == ""
                          ? Default
                          : user3red
                      }
                      onClick={() => (
                        setIdnumer(userdata[5].id),setsearchvalue(arrValue[5])
                      )}
                      alt="Image description"
                      fluid={true}
                      className="TreeNodeImage"
                    />
                    {/* <HoverTreeNode data={userdata[5]} /> */}
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                    { arrValue[5]===undefined? "No Referees": arrValue[5]?.substring(0, 4) +
                      "..." +
                      arrValue[5]?.substring(arrValue[5]?.length - 4)}
                    </p>
                    <p style={{ fontSize: "13px" }}>{userdata[5]?.id}</p>
                  </StyledNode>
                }
              >
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[11].package >= 1
                            ? Active
                            : userdata[11].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[11].id), setsearchvalue(arrValue[11])
                        )}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                      />
                      {/* <HoverTreeNode data={userdata[11]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[11]===undefined? "No Referees": arrValue[11]?.substring(0, 4) +
                      "..." +
                      arrValue[11]?.substring(arrValue[11]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[11]?.id}</p>
                    </StyledNode>
                  }
                />
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[12].package >= 1
                            ? Active
                            : userdata[12].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[12].id), setsearchvalue(arrValue[12])
                        )}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                      />
                      {/* <HoverTreeNode data={userdata[12]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[12]===undefined? "No Referees": arrValue[12]?.substring(0, 4) +
                      "..." +
                      arrValue[12]?.substring(arrValue[12]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[12]?.id}</p>
                    </StyledNode>
                  }
                />
              </TreeNode>
              <TreeNode
                label={
                  <StyledNode style={{cursor:"pointer"}}>
                    <Image
                      style={{ width: "60px" }}
                      src={
                        userdata[6].package >= 1
                          ? Active
                          : userdata[6].id == ""
                          ? Default
                          : user3red
                      }
                      onClick={() => (
                        setIdnumer(userdata[6].id),setsearchvalue(arrValue[6])
                      )}
                      alt="Image description"
                      fluid={true}
                      className="TreeNodeImage"
                    />
                    {/* <HoverTreeNode data={userdata[6]} /> */}
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                    { arrValue[6]===undefined? "No Referees": arrValue[6]?.substring(0, 4) +
                      "..." +
                      arrValue[6]?.substring(arrValue[6]?.length - 4)}
                    </p>
                    <p style={{ fontSize: "13px" }}>{userdata[6]?.id}</p>
                  </StyledNode>
                }
              >
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[13].package >= 1
                            ? Active
                            : userdata[13].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[13].id), setsearchvalue(arrValue[13])
                        )}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                      />
                      {/* <HoverTreeNode data={userdata[13]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[13]===undefined? "No Referees": arrValue[13]?.substring(0, 4) +
                      "..." +
                      arrValue[13]?.substring(arrValue[13]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[13]?.id}</p>
                    </StyledNode>
                  }
                />
                <TreeNode
                  label={
                    <StyledNode style={{cursor:"pointer"}}>
                      <Image
                        style={{ width: "60px" }}
                        src={
                          userdata[14].package >= 1
                            ? Active
                            : userdata[14].id == ""
                            ? Default
                            : user3red
                        }
                        onClick={() => (
                          setIdnumer(userdata[14].id), setsearchvalue(arrValue[14])
                        )}
                        alt="Image description"
                        fluid={true}
                        className="TreeNodeImage"
                      />
                      {/* <HoverTreeNode data={userdata[14]} /> */}
                      <p className="mb-0" style={{ fontSize: "13px" }}>
                      { arrValue[14]===undefined? "No Referees": arrValue[14]?.substring(0, 4) +
                      "..." +
                      arrValue[14]?.substring(arrValue[14]?.length - 4)}
                      </p>
                      <p style={{ fontSize: "13px" }}>{userdata[14]?.id}</p>
                    </StyledNode>
                  }
                />
              </TreeNode>
            </TreeNode>
          </Tree>
        </div>
      </div>
    </>
  );
};

export default MintMainImage;
