import React, { useEffect, useState } from "react";
import Apply from "../Apply/Apply";

import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";
import { Table } from "react-bootstrap";
import Web3 from "web3";
import { Link } from "react-router-dom";

export default function Roadmap() {
  const trackScroll=(e)=>{
    const roadmapWidth = document.querySelector('.roadmap-track').offsetWidth;
if(e=='left'){
document.querySelector('.roadmap-track').scrollLeft -= roadmapWidth;
}else{
document.querySelector('.roadmap-track').scrollLeft += roadmapWidth;
}
}
let webSupply = new Web3("https://bsc-dataseed1.binance.org");

  const [Planes, setPlanes] = useState([]);
  const [Loading_Spinner, setLoading_Spinner] = useState(false);
  const Plane = async () => {
    try {
      setLoading_Spinner(true);
      const contractOf = new webSupply.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );
      let arry = [];
      let obj = {};

      for (let i = 0; i < 9; i++) {
        let Amount = await contractOf.methods.plans(i).call();

        let overallStaked = webSupply.utils.fromWei(Amount.overallStaked.toString());
        overallStaked = parseFloat(overallStaked).toFixed(2);
        arry.push({
          overallStaked: overallStaked,
          stakeDuration: Amount?.stakeDuration,
          stakesCount: Amount?.stakesCount,
          apr: Amount?.apr,
        });
      }
      console.log("arry", arry);
      setPlanes(arry);
      setLoading_Spinner(false);
    } catch (error) {
      setLoading_Spinner(false);

      console.log(error);
    }
  };
  useEffect(() => {
    Plane();
  }, []);
  return (
    <div>
      <section className="breadcrumb-area overlay-dark d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content text-center">
                <h2 className="m-0">Token Distribution</h2>
                <ol className="breadcrumb d-flex justify-content-center">
                  <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active">Tokenomics</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="roadmap">
            <div className="title-section">
                <div className="our-roadmap">
                    <div className="nodes"></div>
                    <p>OUr Roadmap</p>
                    <div className="nodes"></div>
                </div>
                <h2>Digifortune Strategy and <br/> Project <span>Plan Our goal is to
                        reach 1 DigiFortune = 1 BNB</span></h2>
            </div>
            <div className="track-core">
                <div className="track-scroller-core">
                    <div className="track-scroller" onClick={() => trackScroll('left')}>
                        <svg width="25" height="25" viewBox="0 0 25 25"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.8359 12.2415H5.83594" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M12.8359 19.2415L5.83594 12.2415L12.8359 5.24146"
                                stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="track-scroller" onClick={() => trackScroll('right')}>
                        <svg width="24" height="24" viewBox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 5L19 12L12 19" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="roadmap-track">
                <div className="track-item">
                    <div className="style-indicator">
                        <div className="node">
                            <div className="date">Q3 2023</div>
                        </div>
                    </div>
                    <div className="track-item-content-era">
                        <h2>Website Launch </h2>
                        <p>Smart Contract
                            <br/>
                            Social Marketing
                            <br/>
                            Youtube Marketing
                            <br/>
                            1000 Telegram Members
                            <br/>
                            Launch on Pancakeswap
                        </p>
                    </div>
                </div>
                <div className="track-item">
                    <div className="style-indicator">
                        <div className="node">
                            <div className="date">Q1 2024
                            </div>
                        </div>
                    </div>
                    <div className="track-item-content-era">
                        <h2>Building swape

                        </h2>
                        <p> Make Trading Pairs
                            <br/>
                            Additional CEX Listings
                            <br/>
                            digifortune Official Merchandise
                        </p>
                    </div>
                </div>
                <div className="track-item">
                    <div className="style-indicator">
                        <div className="node">
                            <div className="date">Q1 2024
                            </div>
                        </div>
                    </div>
                    <div className="track-item-content-era">
                        <h2>Listing on CoinGecko


                        </h2>
                        <p>Release dex Alpha version
                            <br/>
                            5000 Telegram Members
                            <br/>
                            Listing on Coin Market Cap
                            <br/>
                            CEX Listing
                        </p>
                    </div>
                </div>
                <div className="track-item">
                    <div className="style-indicator">
                        <div className="node">
                            <div className="date">Q2 2024</div>
                        </div>
                    </div>
                    <div className="track-item-content-era">
                        <h2>Building live dex Platfrom
                        </h2>
                        <p>Native Stacking
                            <br/>
                            Flash lonas
                        </p>
                    </div>
                </div>
            </div>
            </div>
        </div>
      <Apply />
    </div>
  );
}
