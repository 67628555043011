import React from 'react'

export default function Apply() {
  return (
    <div>
        <section class="cta-area p-0">
            <div class="container">
                <div class="row">
                    <div class="col-12 card">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-12 col-md-5 text-center">
                                <img src="./MIT_Assets/images/cta_thumb.png" alt=""/>
                            </div>
                            <div class="col-12 col-md-6 mt-4 mt-md-0">
                                <h2 class="m-0">Apply for IGO</h2>
                                <p>Empowering Finance, Unleashing Freedom: Centralize Tomorrow's Exchange</p>
                                <a class="btn btn-bordered active d-inline-block" target='_blank' href="https://presale.digifortune.net"><i class="icon-rocket mr-2"></i>Apply Now</a>
                            </div>
                        </div>
                        <a class="cta-link" target='_blank' href="https://presale.digifortune.net"></a>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
