import React, { useEffect, useState } from "react";
import "./Refferal_Reward.css";
import Web3 from "web3";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";
import Loading_spinner from "../Loading/Loading_spinner";
import MintMainImage from "../Refferal_Tree/Refferal_Tree";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
export default function Refferal_Reward() {
  const [Total_user1, setTotal_user] = useState(0);
  const [Total_StakeAmount1, setTotal_StakeAmount] = useState(0);
  const [Refferal_data, setRefferal_data] = useState([]);
  const [Spinner, setSpinner] = useState(false);
  const { address } = useAccount();

  const emg_data = [
    {
      value: "$67.6k",
      statement: "Income",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-primary dark:text-accent"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      value: "12.6K",
      statement: "Completed",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-success"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
          ></path>
        </svg>
      ),
    },
    {
      value: "143",
      statement: "Pending",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-warning"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      value: "651",
      statement: "Dispatch",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-info"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"></path>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
          ></path>
        </svg>
      ),
    },
  ];

  const WebSupply = new Web3("https://bsc-dataseed.binance.org/");
  const DashBoard_data = async () => {
    try {
      const web3 = window.web3;
      const contractOf = new web3.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );
      let Total_user = await contractOf.methods.getReferralEarnings(address).call();
      let Total_user2 = WebSupply.utils.fromWei(Total_user[1]);
      Total_user = WebSupply.utils.fromWei(Total_user[0]);
      setTotal_StakeAmount(Total_user);
      setTotal_user(Total_user2);
    } catch (error) {
      console.log(error);
    }
  };

  const Get_refferal_info = async () => {
    setSpinner(true);
    try {
      let res = await axios.get(
        `https://refferal.womenempowerment.online/Refferal_Info?Address=${address}`
      );
      console.log("res", res);
      setRefferal_data(res.data.data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  const Claim_reward = async () => {
    try {
      const { request } = await prepareWriteContract({
        address: Mango_Man_Contract,
        abi: Mango_Man_Abi,
        functionName: "claimReferralEarnings",
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      toast.success("Claim Reward Successfully 🎉");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Get_refferal_info();
  }, []);

  useEffect(() => {
    DashBoard_data();
  }, []);

  return (
    <div>
      {Spinner ? (
        <>
          <Loading_spinner />
        </>
      ) : (
        <>
          <section class="breadcrumb-area overlay-dark d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="breadcrumb-content text-center">
                    <h2 class="m-0">Refferal Reward</h2>
                    <ol class="breadcrumb d-flex justify-content-center">
                      <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active">Refferal Reward</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            className="MuiBox-root jss1 sc-ksZaOG gmakkD "
            style={{ height: "auto", marginTop: "5rem" }}
          >
            <div class="my-earning-view">
              <div className="container">
                <div className="row mt-5">
                  <div className=" mt-5 mb-5 grid grid-cols- gap-3 px-4 sm:mt-5 sm:grid-cols-4 sm:gap-5 sm:px-5 lg:mt-6">
                    <div className="  "></div>
                    <div className=" rounded-lg bg-inherit  p-4 card_bg_color ">
                      <div className="flex justify-between space-x-1">
                        <p className="text-xl font-semibold  dark:text-navy-100">
                          {Total_StakeAmount1}
                        </p>
                        {emg_data[0].img}
                      </div>
                      <p className="mt-1 text-xs+ text-white">
                        Total Refferal Invest
                      </p>
                    </div>
                    <div className="rounded-lg bg-slate-100 p-4 card_bg_color dark:bg-navy-600">
                      <div className="flex justify-between space-x-1">
                        <p className="text-xl font-semibold  dark:text-navy-100">
                          {Total_user1}
                        </p>
                        {emg_data[1].img}
                      </div>
                      <p className="mt-1 text-xs+ text-white">
                        Total Refferal Reward
                      </p>
                    </div>
                    <div className="mt-4">
                      <button
                        className="btn input-btn mt-2"
                        onClick={() => Claim_reward()}
                      >
                        Claim Reward
                      </button>
                    </div>
                  </div>
                </div>
                <div class="total-er  mt-5 text-white flex justify-center align-center">
                  <h2 class="mr-auto">Refferal Info</h2>
                </div>
                <Table responsive border>
                  <thead>
                    <tr className="t_head text-white ">
                      <th className="large_address">Refferal Address</th>
                      <th className="large_address">Refferee Address</th>
                      <th className="large_address">Stake Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Refferal_data.map((item, index) => {
                      console.log("item", item);
                      return (
                        <>
                          <tr className="boxx t_head  text-white">
                            <td className="large_address">
                              {item.Refferal_Address}
                            </td>
                            <td className="large_address text-white">
                              {item.Staker_Address}
                            </td>
                            <td className="large_address  text-white">
                              {item.Refferal_Price}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <MintMainImage/>
        </>
      )}
    </div>
  );
}
