// import React, { useEffect, useState } from "react";
// import "./Header.css";
// import { Link, useNavigate } from "react-router-dom";
// import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";
// import Offcanvas from "../Offcanvas/Offcanvas";
// import Offcanvas_nav from "../Offcanvas/Offcanvas";
// import { useDispatch, useSelector } from "react-redux";
// import { Getaddress } from "../../Redux/Slice/Connect_Wallet_Slice";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// // import './jquery.min.js'
// // import './main.js'

// export default function Header() {
//   const [address, setaddress] = useState("Wallet Connect");
//   const [Owner_Address, setOwner_Address] = useState("");
//   const [open, setOpen] = useState(false);

//   const showDrawer = () => {
//     setOpen(true);
//   };

//   const onClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       <header id="header">
//         <nav
//           data-aos="zoom-out"
//           data-aos-delay="800"
//           className="navbar gameon-navbar navbar-expand"
//         >
//           <div className="container header">
//             <a className="navbar-brand" href="/">
//               <img src="https://token.digifortune.net/wp-content/uploads/elementor/thumbs/cropped-output-onlinepngtools-4-qdlk9up5jbkcx1h8bcevvwse5t3qvp0sqcetppgev8.png" alt="Brand Logo" />
//             </a>

//             <div className="ml-auto"></div>
//             <ul className="navbar-nav items mx-auto">
//               <li className="nav-item">
//                 <a className="nav-link" target="_blank" href="https://token.digifortune.net/">
//                   <span style={{fontSize : '13px'}}>About us</span>{" "}
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link">
//                   <Link to="/"><span style={{fontSize : '13px'}}>Staking</span></Link>{" "}
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link">
//                   {" "}
//                   <Link to="/Features"><span style={{fontSize : '13px'}}>Features</span></Link>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link">
//                   <Link to="/Roadmap"><span style={{fontSize : '13px'}}>Roadmap</span></Link>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link">
//                   <Link to="/Refferal_Reward"><span style={{fontSize : '13px'}}>Refferal Reward</span></Link>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link">
//                   <Link to="/Stake_History"><span style={{fontSize : '13px'}}>Stake History</span></Link>
//                 </a>
//               </li>
//                             <li className="nav-item">
//                 <a className="nav-link" target="_blank" href="https://digiswap.net/">
//                   <span style={{fontSize : '13px'}}>Swap</span>
//                 </a>
//               </li>
//               <li className="nav-item ml-4">
//               <ConnectButton
//                    chainStatus="none" 
//                     showBalance={{ smallScreen: false, largeScreen: false }}
//                     classNameName="btn ml-lg-auto btn-bordered-white text-white"
//                   />

//               </li>
//             </ul>
//             <ul className="navbar-nav toggle" onClick={() => showDrawer()}>
//               <li className="nav-item">
//                 <a
//                   href="#"
//                   className="nav-link"
//                   data-toggle="modal"
//                   data-target="#menu"
//                 >
//                   <i className="fa-solid fa-bars"></i>
//                 </a>
//               </li>
//             </ul>
//             <ul className="navbar-nav action"></ul>
//           </div>
//         </nav>
//       </header>
//       <Offcanvas_nav onClose={onClose} showDrawer={showDrawer} open={open} />
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";
import Offcanvas from "../Offcanvas/Offcanvas";
import Offcanvas_nav from "../Offcanvas/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { Getaddress } from "../../Redux/Slice/Connect_Wallet_Slice";
import { ConnectButton } from "@rainbow-me/rainbowkit";
// import './jquery.min.js'
// import './main.js'

export default function Header() {
  const [address, setaddress] = useState("Wallet Connect");
  const [Owner_Address, setOwner_Address] = useState("");
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <header id="header">
        <nav
          data-aos="zoom-out"
          data-aos-delay="800"
          className="navbar gameon-navbar navbar-expand"
        >
          <div className="container header">
            <a className="navbar-brand" href="/">
              <img src="https://token.digifortune.net/assets/imgs/favicon.png" alt="Brand Logo" />
            </a>

            <div className="ml-auto"></div>
            <ul className="navbar-nav items mx-auto">
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/"><span style={{ fontSize: '13px' }}>Home</span></Link>{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/Staking"><span style={{ fontSize: '13px' }}>Staking</span></Link>{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://digifortune.gitbook.io/digifortune.net" target="_blank">
                  {" "}
                  <span style={{ fontSize: '13px' }}>Features</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://token.digifortune.net/assets/content/white-paper.pdf" target="_blank">
                  <span style={{ fontSize: '13px' }}>Whitepaper</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/Refferal_Reward"><span style={{ fontSize: '13px' }}>Refferal Reward</span></Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <Link to="/Stake_History"><span style={{ fontSize: '13px' }}>Stake History</span></Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://digiswap.net">
                  <span style={{ fontSize: '13px' }}>Swap</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://blog.digifortune.net">
                  <span style={{ fontSize: '13px' }}>Blog</span>
                </a>
              </li>
              <li className="nav-item ml-4">
                <ConnectButton
                  chainStatus="none"
                  showBalance={{ smallScreen: false, largeScreen: false }}
                  classNameName="btn ml-lg-auto btn-bordered-white text-white"
                />

              </li>
            </ul>
            <ul className="navbar-nav toggle" onClick={() => showDrawer()}>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#menu"
                >
                  <i className="fa-solid fa-bars"></i>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav action"></ul>
          </div>
        </nav>
      </header>
      <Offcanvas_nav onClose={onClose} showDrawer={showDrawer} open={open} />
    </>
  );
}

