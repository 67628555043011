
export const data=[
    {
        Max_Stake:"10465198604318.78",
        
        Collapse_data:"One"
    },
    {
        Max_Stake:"10497102663593.62",
       
        Collapse_data:"Two"
    },
    {
        Max_Stake:"20999280328863.71",
      
        Collapse_data:"Three"
    },
    {
        Max_Stake:"31499921099444.64",
       
        Collapse_data:"Foure"
    },
    {
        Max_Stake:"41997878334106.46",

        Collapse_data:"Five"
    },
    {
        Max_Stake:"10488823983065.85",
       
        Collapse_data:"Six"
    },
    {
        Max_Stake:"10500000000000.00",
       
        Collapse_data:"Seven"
    },
    {
        Max_Stake:"20999853578772.75",
     
        Collapse_data:"Eight"
    },
    {
        Max_Stake:"31500000000000.00",
      
        Collapse_data:"Nine"
    },
    {
        Max_Stake:"41999999000000.00",
       
        Collapse_data:"ten"
    }
]