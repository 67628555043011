import React from "react";
import { InfinitySpin } from "react-loader-spinner";

export default function Loading_spinner() {
  return (
    <div>
      <div id="gameon-preloader" class="gameon-preloader loaded">
      <InfinitySpin width="300" height="300" color="#fff" />
      </div>
    </div>
  );
}
