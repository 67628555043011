import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../Slice/Connect_Wallet_Slice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage:storageSession,
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, counterReducer)

export const store = configureStore({
  reducer: {
    counter: persistedReducer,
    middleware: [thunk]
  },
})
export const persistor = persistStore(store)