import React, { useEffect, useState } from "react";

import "./Login.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
// import Connect_wallet_modal from "../Connect_wallet_modal/Connect_wallet_modal";
import { Button, Checkbox, Form, Input } from "antd";

import axios from "axios";
import { toast } from "react-hot-toast";

function Login() {
  let isuser = localStorage.getItem("UserAuth");
  const [modalShow, setModalShow] = React.useState(false);
  const [isLogin, setisLogin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinner, setspinner] = useState(false);
  let location = useLocation();

  const history = useNavigate();

  const onFinish = async (values) => {
    setspinner(true);
    console.log("Success:", values);

   
    let res = await axios.post(`https://winner.archiecoin.online/admin_login`, {
      email: values.email,
      password: values.password,
    });
    if (res.data.success == true) {
      toast.success(res.data.msg);
      history("/Admin_Panel");
      localStorage.setItem("UserAuth", true);
      localStorage.setItem("UserEmail", values.email);
      setisLogin(true);
      // handleCancel();
    } else {
      setspinner(false);
      toast.error(res.data.msg);
    }

    console.log("Login_Res", res.data.success);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="">
      
      <div
        className="MuiBox-root jss1 sc-ksZaOG gmakkD "
        style={{ height: "100vh" }}
      >
        <div class="my-earning-view">
          <div className="main_form mt-5">
            <div className="form_ittem">
              <h1 className="text-white">Admin LogIn</h1>
              <div className="inner_form_style">
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="UserName"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item className="d-flex justify-content-center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className=" btn  input-btn mt-2 btn_login"
                    >
                      {spinner == true ? (
                        <>
                          <p class="spinner-border" role="status">
                            <span class="visually-hidden"></span>
                          </p>
                        </>
                      ) : (
                        "LogIn"
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
