import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadWeb3 } from '../../Components/apis/api.js';
import { Mango_Man_Abi, Mango_Man_Contract } from '../../Contract/Contract';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

export const Getaddress = createAsyncThunk("lorem/getData", async (arg, { rejectWithValue }) => {
  try {
    // You can't use useAccount here directly
    return await loadWeb3(); // You might need to update this line based on your requirements
  } catch (error) {
    rejectWithValue(error.response.data);
  }
});

const initialState = {
  Address: "Wallet Connect",
  loading: false,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers: {
    [Getaddress.pending]: (state, { payload }) => {
      state.loading = true;
      console.log("payload", payload);
    },
    [Getaddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Address = payload;
    },
    [Getaddress.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      console.log("payloadpayload", payload);
    },
  },
});

export default counterSlice.reducer;
