import { useEffect, useState } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Landing_page from "./Components/Landing_page/Landing_page";
import Staking from "./Components/Staking/Staking";
import Footer from "./Components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Main_Landing from "./Main_Landing";
import Features from "./Components/Features/Features";
import Roadmap from "./Components/Roadmap/Roadmap";
import Contact_Us from "./Components/Contact_Us/Contact_Us";
import { Toaster } from "react-hot-toast";
import Refferal_Reward from "./Components/Refferal_Reward/Refferal_Reward";
import Admin_Panel from "./Components/Admin_Panel/Admin_Panel";
import PrivateRoutes from "./Components/Private";
import Login from "./Components/Login/Login";
import Wallet_Connect_page from "./Components/Wallet_Connect/Wallet_Connect_page";
import Stake_History from "./Components/Stake_History/Stake_History";
import MintMainImage from "./Components/Refferal_Tree/Refferal_Tree";
import io from 'socket.io-client';

const socket = io('http://localhost:3344');
function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Listen for 'initialData' event from the server and update the state
    socket.on('connection', (data) => {
      console.log("initialData");
    });

    // return () => {
    //   socket.disconnect(); // Clean up WebSocket connection on unmount
    // };
  }, []);

  return (
    <>
      <div className="main">
        <Header />
        <Toaster />
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/Staking" element={<Main_Landing />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/Roadmap" element={<Roadmap />} />
          <Route path="/Contact_Us" element={<Contact_Us />} />
          <Route path="/Refferal_Reward" element={<Refferal_Reward />} />
          <Route path="/Admin_LogIn" element={<Login />} />
          <Route path="/Wallet_Connect" element={<Wallet_Connect_page />} />
          <Route path="/Stake_History" element={<Stake_History />} />
          <Route path="/RefferalTree" element={<MintMainImage />} />

          <Route element={<PrivateRoutes />}>
            <Route path="/Admin_Panel" element={<Admin_Panel />} />
          </Route>
        </Routes>

        <Footer />
      </div>
    </>
  );
}

export default App;
