import React from "react";
import Landing_page from "./Components/Landing_page/Landing_page";
import Staking from "./Components/Staking/Staking";
import Apply from "./Components/Apply/Apply";
import Wallet_Connect_page from "./Components/Wallet_Connect/Wallet_Connect_page";
import { useSelector } from "react-redux";
import Loading_spinner from "./Components/Loading/Loading_spinner";
import { useAccount } from "wagmi";

export default function Main_Landing() {
  const lorem = useSelector((state) => state.counter.Address);
  const { address } = useAccount();

  return (
    <div>
      {address? (
        <>
          <Staking />
        </>
      ) : (
        <Wallet_Connect_page />
      )}
    </div>
  );
}
