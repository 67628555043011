// ExportPage.jsx
import "./Home.css";
import React from 'react';

const ExportPage = () => {
  return (
    <>
    <iframe src="https://token.digifortune.net?query=iframe" frameborder="0"></iframe>
    </>
  );
};

export default ExportPage;
