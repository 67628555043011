
import { Outlet, Navigate } from 'react-router-dom'
const PrivateRoutes = () => {
    let isuser= localStorage.getItem("UserAuth")

 
    return (
        <div className='bg_Dashboar'>
            {

                !isuser ? <Navigate to="/Admin_LogIn" /> : <Outlet />
            }
        </div>
    )
}
export default PrivateRoutes

