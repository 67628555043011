import React from "react";
import { useCollapse } from "react-collapsed";

export default function Collapse(props) {
  const config = {
    defaultExpanded: props.defaultExpanded || false,
    // collapsedHeight: props.collapsedHeight || 0
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  return (
    <div>
      <div class="card-header bg-inherit border-0 p-0" {...getToggleProps()}>
        <h2 class="m-0">
          <button
            class="btn staking-btn d-block text-left w-100 py-4"
            type="button"
            data-toggle="collapse"
            data-target="#collapseTwo"
          >
            <div class="row">
              <div class="col-12 col-md-8">
                <div class="media flex-column flex-md-row">
                  <img
                  style={{height: "5rem", width: "5rem", marginTop: "2rem"}}
                    class="avatar-max-lg"
                    src="https://token.digifortune.net/assets/imgs/favicon.png"
                    alt=""
                  />
                  <div class="content media-body mt-4 mt-md-0 ml-md-4">
                    <h4 style={{fontSize: "20px"}} class="m-0">Stake DIFO to receive a suite of benefits including: Stake to Trade for Free, Launchpad Tokens and Tomorrow's Exchange Benfits</h4>
                    <span class="d-inline-block mt-2">DIGIFORTUNE</span>
                    <p>
                    All staked virtual assets are segregated in separate wallets and staked to highest performing validators with best-in-class security controls                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row staking-info align-items-center justify-content-center mt-4 mt-md-5">
              <div class="col single-item">
                <span>{props.Max_Stake}</span>
                <span>Staked</span>
              </div>
              <div class="col single-item">
                <span>{props.stakeDuration / Number(86400)} Days</span>
                <span>Lock Duration</span>
              </div>
              <div class="col single-item">
                <span>{props.apr}%</span>
                <span>APY</span>
              </div>
              <div class="col single-item">
                <span>FIXED</span>
                <span>Lock Type</span>
              </div>
              <div class="col single-item">
                <span> {props.earlyPenalty}</span>
                <span>Early Penalty</span>
              </div>
            </div>
          </button>
        </h2>
      </div>
      <div id="collapseTwo" data-parent="#gameon-accordion">
        <div class="card-body" {...getCollapseProps()}>
          <div class="row">
            <div class="col-12 col-md-4 single-staking-item input-box">
              <span class="item-title mb-2">Deposit</span>
              <div class="input-area d-flex flex-column">
                <div class="input-text">
                  <input
                    type="text"
                    placeholder="0.00"
                    onChange={(e) => props.setgetValue(e.target.value)}
                  />
                  {/* <a href="#">Max</a> */}
                </div>
                {props.stakeDuration == 0 ? (
                  <>
                    <div
                      class="MuiBox-root jss404"
                      onClick={() => (
                        props.UnStake(props.index),
                        props.setConditionAl(props.index)
                      )}
                    >
                      <button class="sc-dkzDqf fAroC">
                        {props.ConditionAl == props.index ? (
                          <>
                            {props.Spinner ? (
                              <>
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden"></span>
                                </div>
                              </>
                            ) : (
                              "UnStake"
                            )}
                          </>
                        ) : (
                          "UnStake"
                        )}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      class=""
                      onClick={() => (
                        props.Stake(props.index, props.Max_Stake,props.apr,props.stakeDuration),
                        props.setConditionAl(props.index)
                      )}
                    >
                      <button class="btn input-btn mt-2">
                        {props.ConditionAl == props.index ? (
                          <>
                            {props.Spinner ? (
                              <>
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden"></span>
                                </div>
                              </>
                            ) : (
                              "Stake"
                            )}
                          </>
                        ) : (
                          "Stake"
                        )}
                      </button>
                    </div>
                  </>
                )}
                {/* <a href="#" class="btn input-btn mt-2" onClick={()=>props.Stake(props.index,props.Max_Stake)}>
                  Approve
                </a> */}
              </div>
            </div>

            <div class="col-12 col-md-4 single-staking-item input-box">
              <span class="item-title mb-2">Withdraw</span>
              <div class="input-area d-flex flex-column">
                {props.stakeDuration == 0 ? (
                  <a href="#" class="btn input-btn mt-2"  onClick={() => (
                    props.UnStake(props.index),props.setConditionAl(props.index)
                   )}>
                    UnStake
                  </a>
                ) : (
                  <>
                    <button disabled={true} href="#" class="btn input-btn mt-2 disable_btn">
                      UnStake
                    </button>
                  </>
                )}
              </div>
            </div>

            <div class="col-12 col-md-4 single-staking-item input-box">
              <span class="item-title mb-2">Pending rewards</span>
              <div class="input-area d-flex flex-column">
                {/* <h4 class="price m-0">0.00 BUSD</h4>
                <span class="reward my-2">
                  Rewards are depleted, ask admins to fund it.
                </span> */}
                <a  class="btn input-btn mt-2" onClick={()=> props.claim(props.index) }>
                  <svg
                    class="svg-inline--fa fa-lock mr-1"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="lock"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"
                    ></path>
                  </svg>
                  Claim
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
