import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Getaddress } from "../../Redux/Slice/Connect_Wallet_Slice";
import { Link } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function Wallet_Connect_page() {
  const lorem = useSelector((state) => state.counter.Address);

  const dispatch = useDispatch();
  const { address } = useAccount();


  return (
    <div>
      <section class="wallet-connect-area">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
              <div class="intro text-center">
                <div class="intro-content">
                  <span class="intro-text">Wallet Connect</span>
                  <h3 class="mt-3 mb-0">Connect your Wallet</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center items">
            <div class="col-12 col-md-6 col-lg-4 item">
              <div class="card single-wallet">
                <a class="d-block text-center">
                  <img
                    class="avatar-lg"
                    src="./MIT_Assets/images/metamask.png"
                    alt=""
                  />
                  <h4 class="mb-0">MetaMask</h4>
                  <p>
                    A browser extension with great flexibility. The web's most
                    popular wallet
                  </p>
                  <div className="d-flex justify-center" style={{display:"flex",justifyContent:"center"}}>

                  <ConnectButton
                   chainStatus="56" 
                    showBalance={{ smallScreen: false, largeScreen: false }}
                    className="btn ml-lg-auto btn-bordered-white text-white"
                  />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
