import React, { useEffect, useState } from "react";
import "./Admin_Panel.css";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { InfinitySpin } from "react-loader-spinner";
import axios from "axios";
import { Button, Checkbox, Form, Input } from "antd";
import { toast } from "react-hot-toast";
import { Mango_Man_Abi, Mango_Man_Contract } from "../../Contract/Contract";
import Loading_spinner from "../Loading/Loading_spinner";
import { useSelector } from "react-redux";
import Wallet_Connect_page from "../Wallet_Connect/Wallet_Connect_page";
import moment from "moment";
import { useAccount } from "wagmi";
import Web3 from "web3";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import { readContract } from "@wagmi/core";


export default function Admin_Panel() {
  const lorem = useSelector((state) => state.counter.Address);
  const { address } = useAccount();
  const [Total_user1, setTotal_user] = useState(0);
  const [Total_StakeAmount1, setTotal_StakeAmount] = useState(0);
  const [Total_UnStakeAmount1, setTotal_UnStakeAmount] = useState(0);
  const [Total_Unstake_User1, setTotal_Unstake_User] = useState(0);
  const [Planes, setPlanes] = useState([]);
  const [Loading_Spinner, setLoading_Spinner] = useState(false);
  const [Refferal_data, setRefferal_data] = useState([]);
  const [spinner, setspinner] = useState(false);

  const emg_data = [
    {
      value: "$67.6k",
      statement: "Income",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-primary dark:text-accent"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      value: "12.6K",
      statement: "Completed",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-success"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
          ></path>
        </svg>
      ),
    },
    {
      value: "143",
      statement: "Pending",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-warning"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      value: "651",
      statement: "Dispatch",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-info"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"></path>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
          ></path>
        </svg>
      ),
    },
  ];
  let webSupply = new Web3("https://bsc-dataseed1.binance.org");


  const DashBoard_data = async () => {
    try {
      const contractOf = new webSupply.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );

      let Total_user = await contractOf.methods.TotalPlan().call();

      setTotal_user(Total_user);
      let Total_StakeAmount = await contractOf.methods
        .totalStakeAmount()
        .call();
      setTotal_StakeAmount(webSupply.utils.fromWei(Total_StakeAmount.toString()));

      let Total_UnStakeAmount = await contractOf.methods
        .totalunstakeAmount()
        .call();
      setTotal_UnStakeAmount(
        webSupply.utils.fromWei(Total_UnStakeAmount.toString())
      );

      let Total_Unstake_User = await contractOf.methods.TotalUstake().call();
      setTotal_Unstake_User(Total_Unstake_User);
    } catch (error) {
      console.log(error);
    }
  };
  const Plane = async () => {
    try {
      setLoading_Spinner(true);
      const contractOf = new webSupply.eth.Contract(
        Mango_Man_Abi,
        Mango_Man_Contract
      );
      let arry = [];
      let obj = {};

      for (let i = 0; i < 9; i++) {
        let Amount = await contractOf.methods.plans(i).call();

        let overallStaked = webSupply.utils.fromWei(Amount.overallStaked.toString());
        overallStaked = parseFloat(overallStaked).toFixed(2);
        arry.push({
          overallStaked: overallStaked,
          stakeDuration: Amount?.stakeDuration,
          stakesCount: Amount?.stakesCount,
          apr: Amount?.apr,
        });
      }
      console.log("arry", arry);
      setPlanes(arry);
      setLoading_Spinner(false);
    } catch (error) {
      setLoading_Spinner(false);

      console.log(error);
    }
  };
  const Get_refferal_info = async () => {
  
    try {
      let res = await axios.get(
        `https://refferal.womenempowerment.online/Get_Refferal_data`
      );
      console.log("res", res);
      res = res.data.data;
      let arr = [];
      res.forEach((item, index) => {  
      arr.push({
          Refferal_Address: item?.Refferal_Address,
          Staker_Address: item?.Staker_Address,
          Refferal_Price: item?.Refferal_Price,
        });
      });
      setRefferal_data(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const [get_Stake_History, setget_Stake_History] = useState([])

  const Get_Stake_history = async () => {
    try {

        let res= await axios.get(`https://refferal.womenempowerment.online/Get_Stake_History`)
        console.log("Res_Sa",res);
        setget_Stake_History(res.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    DashBoard_data();
    Get_refferal_info();
    Get_Stake_history()
    Plane();
  }, []);

  const onFinish = async (values) => {
    try {
   
      let Owner = await readContract({
        address:Mango_Man_Contract,
        abi:Mango_Man_Abi,
        functionName: "owner",
      });
      if (Owner == address) {
        const { request } = await prepareWriteContract({
          address: Mango_Man_Contract,
          abi: Mango_Man_Abi,
          functionName: "setStakeDuration",
          args: [values.Plane_Id,values.duration],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Stake Duration Successfully Set 🎉");
      } else {
        toast.error("You Are Owner");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish1 = async (values) => {
    try {

      let Owner = await readContract({
        address:Mango_Man_Contract,
        abi:Mango_Man_Abi,
        functionName: "owner",
      });
      if (Owner == address) {
        const { request } = await prepareWriteContract({
          address: Mango_Man_Contract,
          abi: Mango_Man_Abi,
          functionName: "setAPR",
          args: [values.Plane_Id,values.Percent],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Stake APR Successfully Set 🎉");
      } else {
        toast.error("You Are Owner");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish2 = async (values) => {
    try {
      let Owner = await readContract({
        address:Mango_Man_Contract,
        abi:Mango_Man_Abi,
        functionName: "owner",
      });
      if (Owner == address) {
        const { request } = await prepareWriteContract({
          address: Mango_Man_Contract,
          abi: Mango_Man_Abi,
          functionName: "setWithdrawDeduction",
          args: [values.Plane_Id,values.Deduction],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Withdraw Deduction Successfully Set 🎉");
      } else {
        toast.error("You Are Owner");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish3 = async (values) => {
    try {
      let Owner = await readContract({
        address:Mango_Man_Contract,
        abi:Mango_Man_Abi,
        functionName: "owner",
      });
      if (Owner == address) {
        const { request } = await prepareWriteContract({
          address: Mango_Man_Contract,
          abi: Mango_Man_Abi,
          functionName: "setPlanLimitAmount",
          args: [values.Plane_Id,values.Limit_Amount],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Plan Limit Amount Successfully Set 🎉");
      } else {
        toast.error("You Are Owner");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {address ? (
        <>
          <div
            className="MuiBox-root jss1 sc-ksZaOG gmakkD "
            style={{ height: "auto" }}
          >
            <div class="my-earning-view">
              {Loading_Spinner ? (
                <>
                  <Loading_spinner />
                </>
              ) : (
                <>
                  <div className="container">
                    <div class="total-er text-white flex justify-center align-center">
                      <Link to="/" className="nav_link">
                        <svg
                          class="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "5px",
                          }}
                        >
                          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="row mt-5 mb-5">
                      <div className=" mt-5 mb-5 grid grid-cols-2 gap-3 px-4 sm:mt-5 sm:grid-cols-4 sm:gap-5 sm:px-5 lg:mt-6">
                        <div className=" rounded-lg bg-slate-100 p-4 card_bg_color ">
                          <div className="flex justify-between space-x-1">
                            <p className="text-xl font-semibold  dark:text-navy-100">
                              {Total_user1}
                            </p>
                            {emg_data[0].img}
                          </div>
                          <p className="mt-1 text-xs+ text-white">Total User</p>
                        </div>
                        <div className="rounded-lg bg-slate-100 p-4 card_bg_color dark:bg-navy-600">
                          <div className="flex justify-between space-x-1">
                            <p className="text-xl font-semibold  dark:text-navy-100">
                              {Total_StakeAmount1}
                            </p>
                            {emg_data[1].img}
                          </div>
                          <p className="mt-1 text-xs+ text-white">
                            Total Stake Amount
                          </p>
                        </div>
                        <div className="rounded-lg bg-slate-100 p-4 card_bg_color dark:bg-navy-600">
                          <div className="flex justify-between space-x-1">
                            <p className="text-xl font-semibold  dark:text-navy-100">
                              {Total_UnStakeAmount1}
                            </p>
                            {emg_data[2].img}
                          </div>
                          <p className="mt-1 text-xs+ text-white">
                            Total UnStake Amount
                          </p>
                        </div>
                        <div className="rounded-lg bg-slate-100 p-4 card_bg_color dark:bg-navy-600">
                          <div className="flex justify-between space-x-1">
                            <p className="text-xl font-semibold  dark:text-navy-100">
                              {Total_Unstake_User1}
                            </p>
                            {emg_data[3].img}
                          </div>
                          <p className="mt-1 text-xs+ text-white">
                            {" "}
                            Total UnStake User
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="total-er  mt-5 text-white flex justify-center align-center">
                      <h2 class="mr-auto"> Change Planes Info</h2>
                    </div>

                    <div className="row ">
                      <div className="col-lg-3 col-md-6 mt-5">
                        <div className="card_bg_Write">
                          <h5>Set Stake Duration</h5>
                          <small>
                            Please Enter Plane Number and Stake Duration
                          </small>
                          <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                          >
                            <Form.Item
                              label="Plane Id"
                              name="Plane_Id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake Plane Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label="Duration"
                              name="duration"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake Duration!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn  input-btn mt-2 pb-4 pt-2"
                              >
                                {spinner == true ? (
                                  <>
                                    <p class="spinner-border" role="status">
                                      <span class="visually-hidden"></span>
                                    </p>
                                  </>
                                ) : (
                                  "Write"
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-5">
                        <div className="card_bg_Write">
                          <h5>Set Stake APR</h5>
                          <small>
                            Please Enter Plane Number and Stake APR Percent
                          </small>
                          <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish1}
                            autoComplete="off"
                          >
                            <Form.Item
                              label="Plane Id"
                              name="Plane_Id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake Plane Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label="Percent"
                              name="Percent"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake ARP Percent!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn  input-btn mt-2 pb-4 pt-2"
                              >
                                {spinner == true ? (
                                  <>
                                    <p class="spinner-border" role="status">
                                      <span class="visually-hidden"></span>
                                    </p>
                                  </>
                                ) : (
                                  "Write"
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-5">
                        <div className="card_bg_Write">
                          <h5>Set Withdraw Deduction</h5>
                          <small>
                            Please Enter Plane Number and Withdraw Deduction
                            Amount
                          </small>
                          <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish2}
                            autoComplete="off"
                          >
                            <Form.Item
                              label="Plane Id"
                              name="Plane_Id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake Plane Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label="Deduction"
                              name="Deduction"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Deduction Amount!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn  input-btn mt-2 pb-4 pt-2"
                              >
                                {spinner == true ? (
                                  <>
                                    <p class="spinner-border" role="status">
                                      <span class="visually-hidden"></span>
                                    </p>
                                  </>
                                ) : (
                                  "Write"
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-5">
                        <div className="card_bg_Write">
                          <h5>Set Plan Limit Amount</h5>
                          <small>
                            Please Enter Plane Number and Limit Amount
                          </small>
                          <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish3}
                            autoComplete="off"
                          >
                            <Form.Item
                              label="Plane Id"
                              name="Plane_Id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Stake Plane Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label=" Limit_Amount"
                              name=" Limit_Amount"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input  Limit Amount!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn input-btn mt-2 pb-4 pt-2"
                              >
                                {spinner == true ? (
                                  <>
                                    <p class="spinner-border" role="status">
                                      <span class="visually-hidden"></span>
                                    </p>
                                  </>
                                ) : (
                                  "Write"
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div class="total-er  mt-5 text-white flex justify-center align-center">
                      <h2 class="mr-auto">All Refferal Data</h2>
                    </div>
                    <Table responsive border>
                      <thead>
                        <tr className="t_head text-white ">
                          <th className="large_address">Refferal Address</th>
                          <th className="large_address">Refferee Address</th>
                          <th className="large_address">Refferal Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Refferal_data.map((item, index) => {
                          return (
                            <>
                              <tr className="boxx t_head  text-white">
                                <td className="large_address">
                                  {item.Refferal_Address}
                                </td>
                                <td className="large_address text-white">
                                  {item.Staker_Address}
                                </td>
                                <td className="large_address  text-white">
                                  {item.Refferal_Price}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div class="total-er  mt-5 text-white flex justify-center align-center">
                      <h2 class="mr-auto">Staking Hisotry</h2>
                    </div>
                    <Table responsive border>
                      <thead>
                        <tr className="t_head text-white ">
                          <th className="large_address">Address</th>
                          <th className="large_address">Stake Amount</th>
                          <th className="large_address">APR</th>
                          <th className="large_address">Stake Duration</th>
                          <th className="large_address">Stake Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {get_Stake_History.map((item, index) => {
                          return (
                            <>
                               <tr className="boxx t_head  text-white">
                        <td className="large_address">{item.Address}</td>
                        <td className="large_address text-white">
                          {item.Amount}
                        </td>
                        <td className="large_address  text-white">
                          {item.APR}
                        </td>
                        <td className="large_address  text-white">
                          {item.Duration} Days
                        </td>
                        <td className="large_address  text-white">
                          { moment(item.Date*1000).format("DD/MM/YYYY h:m:s A")}
                        </td>
                      </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div class="total-er  mt-5 text-white flex justify-center align-center">
                      <h2 class="mr-auto"> All Plans Info</h2>
                    </div>
                    <Table responsive border>
                      <thead>
                        <tr className="t_head text-white ">
                          <th className="large_address">Overall Staked</th>
                          <th className="large_address">Stakes Count</th>
                          <th className="large_address">APR</th>
                          <th className="large_address">Stake Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Planes.map((item, index) => {
                          return (
                            <>
                              <tr className="boxx t_head  text-white">
                                <td className="large_address">
                                  {item.overallStaked}
                                </td>
                                <td className="large_address text-white">
                                  {item.stakesCount}
                                </td>
                                <td className="large_address  text-white">
                                  {item.apr}
                                </td>
                                <td className="large_address  text-white">
                                  {item.stakeDuration}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <Wallet_Connect_page />
      )}
    </div>
  );
}
