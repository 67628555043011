import React from 'react'
import {FiFacebook,FiTwitter,FiLinkedin} from 'react-icons/fi'
import {AiOutlineReddit} from 'react-icons/ai'
// import {SlSocialVkontakte} from 'react-icons/si'
import {CiYoutube} from 'react-icons/ci'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <>
    <footer class="footer-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center">
                       
                        <div class="footer-items">
                     
                            <a class="navbar-brand" href='/'>
                                <img src="https://token.digifortune.net" alt="" style={{maxWidth:"50%"}}/>
                            </a>
                           
                            <div class="social-icons d-flex justify-content-center my-4">
                                <a class="facebook" href="https://www.facebook.com/Digifortune.net" target="_blank">
                                    <i class="  "> <FiFacebook/></i>
                                    <i class=""> <FiFacebook/></i>
                                </a>
                                <a class="twitter" href="https://twitter.com/digifortun" target="_blank">
                                    <i class=""><FiTwitter/></i>
                                    <i class=""><FiTwitter/></i>
                                </a>
                                <a class="linkedin" href="https://www.linkedin.com/company/digifortune" target="_blank">
                                    <i class=""><FiLinkedin/></i>
                                    <i class=""><FiLinkedin/></i>
                                </a>
                                <a class="reddit" href="https://www.reddit.com/user/Digifortune" target="_blank">
                                    <i class=""><AiOutlineReddit/></i>
                                    <i class=""><AiOutlineReddit/></i>
                                </a>
                                {/* <a class="vkontakte" href="https://discord.com/" target="_blank">
                                    <i class=""><SlSocialVkontakte/></i>
                                    <i class=""><SlSocialVkontakte/></i>
                                </a> */}
                                <a class="youtube" href="https://www.youtube.com/@DigiFortunecrypto" target="_blank">
                                    <i class=""><CiYoutube/></i>
                                    <i class=""><CiYoutube/></i>
                                </a>
                            </div>
                            <ul class="list-inline">
                                <li class="list-inline-item"><a ><Link to="/Features">Features</Link></a></li>
                                <li class="list-inline-item"><a ><Link to="/Roadmap">Roadmap</Link></a></li>
                                <li class="list-inline-item"><a ><Link to="/Contact_Us">How It Works</Link></a></li>
                                {/* <li class="list-inline-item"><a href="blog.html">Blog</a></li>
                                <li class="list-inline-item"><a href="login.html">Privacy Policy</a></li> */}
                            </ul>
                            
                            <div class="copyright-area py-4">©2024 DIFO, All Rights Reserved By <a href="DigiFortune" target="_blank">DigiFortune</a></div>
                        </div>
                        
                        <div id="scroll-to-top" class="scroll-to-top">
                            <a href="#header" class="smooth-anchor">
                                <svg class="svg-inline--fa fa-arrow-up" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                                    <path fill="currentColor" d="M374.6 246.6C368.4 252.9 360.2 256 352 256s-16.38-3.125-22.62-9.375L224 141.3V448c0 17.69-14.33 31.1-31.1 31.1S160 465.7 160 448V141.3L54.63 246.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160C387.1 213.9 387.1 234.1 374.6 246.6z"></path></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>
  )
}
